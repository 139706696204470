<script>
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import api from '@/services/api';
import {BAvatar, BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow} from 'bootstrap-vue';
import draggable from 'vuedraggable';
import vSelect from 'vue-select';
import {i18n} from '@/libs/i18n';
import {offerTypes} from '@/constants/offerTypes';
import {
    appendToFormData,
    sliceOnlyFirstLetter,
    sortItems,
    updateObjectToNew
} from '@/util/helper';
import PageTitle from '@/components/PageTitle';
import TableActionBtns from '@/components/TableActionBtns';
import UploadImage from '@/components/ui/UploadImage/UploadImage';
import CustomInput from '@/components/ui/CustomInput/CustomInput';

export default {
    name: 'LevelsGifts',
    components: {
        PageTitle,
        TableActionBtns,
        CustomInput,
        UploadImage,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BAvatar,
        draggable,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data: () => ({
        levels: [],
        levelsGifts: [],
        dataForModal: {
            name: {
                uz: '',
                ru: '',
                en: ''
            },
            type: null,
            package_id: null,
            level_id: null,
            published: false,
            count: null,
            image: null,
            position: null,
            count_draft: null,
            probability: null,
            amount: 0
        },
        filterByLevelID: 0
    }),
    computed: {
        computedLevelGifts: {
            get() {
                return this.levelsGifts.filter((gift) => {
                    if (this.filterByLevelID) {
                        return gift.level.id === Number(this.filterByLevelID);
                    }
                    return gift;
                });
            },
            set(newValue) {
                this.levelsGifts = newValue;
            }
        },
        computedLevelsForFilter() {
            const levelsForFilter = JSON.parse(JSON.stringify(this.levels));
            levelsForFilter.unshift({
                id: 0,
                name: 'All'
            });
            return levelsForFilter;
        },
        computedOfferTypes() {
            let newList = JSON.parse(JSON.stringify(offerTypes));
            newList = [
                ...newList,
                {
                    key: 'prize',
                    label: i18n.t('titles.prize')
                },
                {
                    key: 'paynet',
                    label: 'paynet'
                },
                {
                    key: 'coin',
                    label: 'coin'
                }
            ];
            return newList;
        },
        computedAmountInput() {
            return this.dataForModal.type === 'paynet' || this.dataForModal.type === 'coin';
        }
    },
    methods: {
        async getLevelsGifts() {
            const {data} = await api.levels.getLevelGifts();
            this.levelsGifts = data.map((item) => {
                if (item.published) {
                    item.published = 1;
                } else {
                    item.published = 0;
                }

                return item;
            });
            this.sortLevelGiftsByPosition();
        },

        async create() {
            const {formData, config} = this.formDataCreator();
            const {data} = await api.levels.createLevelGift(
                this.dataForModal.level_id,
                formData,
                config
            );
            this.levelsGifts.push(data);
            this.sortLevelGiftsByPosition();
            this.$toast.success(this.$t('success.added'));
        },

        async update() {
            const {formData, config} = this.formDataCreator();
            const {data} = await api.levels.updateLevelGift(
                this.updatingItem.level.id,
                this.updatingItem.id,
                formData,
                config
            );
            this.levelsGifts.forEach((gift) => {
                if (gift.id === this.updatingItem.id) {
                    updateObjectToNew(gift, data);
                }
            });
            this.$toast.success(this.$t('success.updated.title'));
        },

        async deleteGift(id, index) {
            const levelID = this.levelsGifts.find((item) => {
                if (item.id === id) return item;
            }).level.id;
            await api.levels.deleteLevelGift(levelID, id);
            this.levelsGifts.splice(index, 1);
        },

        async updateProbability(levelID, giftID, body) {
            try {
                await api.levels.updateLevelGiftProbabilityApi(levelID, giftID, {
                    probability: body
                });
                this.$toast.success(this.$t('success.updated.probability'));
            } catch (error) {
                this.$toast.error(this.$t('error.smth'));
            }
        },

        async getLevels() {
            const {data} = await api.levels.get();
            this.levels = data.map((level) => {
                level.name = level.name[this.localLang];
                return level;
            });
        },

        async updatePosition({moved}) {
            const id = moved.element.id;
            const position = moved.newIndex;

            await api.levelsGifts
                .put(id, {position})
                .then(() => {
                    this.$toast.success(this.$t('success.updated.position'));
                })
                .catch(() => {
                    this.$toast.error(this.$t('error.smth'));
                });
        },

        getFirstLetter(str) {
            return sliceOnlyFirstLetter(str);
        },

        sortLevelGiftsByPosition() {
            this.levelsGifts = sortItems(this.levelsGifts, 'position');
        },

        formDataCreator() {
            const body = Object.assign({}, this.dataForModal);
            if (!body.published) {
                body.published = false;
            }

            if (!body.amount) {
                body.amount = 0;
            }

            if (typeof body.image === 'string') {
                delete body.image;
            }

            // delete body.image;
            let formData = appendToFormData(body);
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            return {formData, config};
        }
    },
    mounted() {
        this.getLevelsGifts();
        this.getLevels();
        if (this.$route.query.id) {
            this.filterByLevelID = this.$route.query.id;
        }
    },
    watch: {
        filterByLevelID(newValue) {
            if (this.filterByLevelID !== null && this.$route.query.id !== Number(newValue)) {
                this.$router.push({
                    query: {
                        id: this.filterByLevelID === 0 ? 'all' : this.filterByLevelID
                    }
                });
            }
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.levelsGifts') }}
            </template>
            <template v-if="hasAccess('levels_gifts', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon"/>
                </b-button>
            </template>
        </page-title>

        <b-row class="mb-2">
            <b-col lg="3" sm="6">
                <v-select
                    v-model="filterByLevelID"
                    :disabled="!levelsGifts.length"
                    :options="computedLevelsForFilter"
                    :placeholder="$t('choose.level')"
                    :reduce="(level) => level.id"
                    :searchable="false"
                    label="name"
                    style="background-color: #fff; text-transform: capitalize"
                />
            </b-col>
        </b-row>

        <div class="card">
            <div class="informations-draggable-table">
                <table>
                    <thead>
                    <tr>
                        <th></th>
                        <th>
                            <div class="d-flex justify-content-center">#</div>
                        </th>
                        <th>
                            {{ $t('titles.picture') }}
                        </th>
                        <th>
                            {{ $t('titles.title') }}
                        </th>
                        <th>
                            {{ $t('titles.level') }}
                        </th>
                        <th>
                            {{ $t('titles.probability') }}
                        </th>
                        <th>
                            {{ $t('titles.status') }}
                        </th>
                        <th>
                            {{ $t('titles.type') }}
                        </th>
                        <th>
                            {{ $t('titles.amount') }}
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <draggable
                        v-model="computedLevelGifts"
                        handle=".handle"
                        tag="tbody"
                        @change="updatePosition"
                    >
                        <tr v-for="(gift, index) in computedLevelGifts" :key="index">
                            <td>
                                <div class="handle cursor-move">
                                    <feather-icon icon="AlignJustifyIcon"/>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center">
                                    {{ index + 1 }}
                                </div>
                            </td>
                            <td>
                                <b-avatar
                                    :src="gift.image"
                                    :text="getFirstLetter(gift.name[localLang])"
                                    size="lg"
                                />
                            </td>
                            <td>
                                {{ gift.name[localLang] }}
                            </td>
                            <td style="min-width: 100px; text-transform: capitalize">
                                {{ gift.level.name[localLang] }}
                            </td>
                            <td>
                                <custom-input
                                    v-if="hasAccess('levels_gifts', 'update')"
                                    v-model="gift.probability"
                                    style="width: 130px; padding: 1px 0"
                                    type="number"
                                    @submit="
                                        updateProbability(gift.level.id, gift.id, gift.probability)
                                    "
                                >
                                    <template #append>%</template>
                                </custom-input>
                                <template v-else> {{ gift.probability }}%</template>
                            </td>
                            <td>
                                <feather-icon
                                    :class="[
                                        'stroke-current',
                                        generateIconColorToBoolean(!!gift.published)
                                    ]"
                                    :icon="generateIconToBoolean(!!gift.published)"
                                />
                            </td>
                            <td>
                                {{ $t(`titles.${gift.type}`) }}
                            </td>
                            <td>
                                {{ gift.count }}
                            </td>
                            <td>
                                <table-action-btns
                                    :delete-access="{
                                        section: 'levels_gifts',
                                        permission: 'delete'
                                    }"
                                    :delete-handler="deleteGift"
                                    :index="index"
                                    :item="gift"
                                    :update-access="{
                                        section: 'levels_gifts',
                                        permission: 'update'
                                    }"
                                    @openModal="openModalToUpdate"
                                />
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>

        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="false"
            :title="$t('add.gift')"
            cancel-variant="danger"
            centered
            size="xl"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME UZ    -->
                    <b-col md="4">
                        <label>{{ $t('titles.title') }} (uz)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} (uz)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} uz`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME RU    -->
                    <b-col md="4">
                        <label>{{ $t('titles.title') }} (ru)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} (ru)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} ru`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME EN    -->
                    <b-col md="4">
                        <label>{{ $t('titles.title') }} (en)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} (en)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} en`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    Image    -->
                    <b-col md="4">
                        <label for="guideImage">
                            {{ $t('titles.picture') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.picture')"
                            rules="required"
                        >
                            <b-form-group>
                                <upload-image
                                    id="guideImage"
                                    v-model="dataForModal.image"
                                    :error="errors.length > 0 ? true : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPE    -->
                    <b-col md="4">
                        <label>
                            {{ $t('titles.types') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.types')"
                            rules="required"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="dataForModal.type"
                                    :class="[
                                        'select-size-lg',
                                        {
                                            'v-select--is-invalid': errors.length > 0
                                        }
                                    ]"
                                    :options="computedOfferTypes"
                                    :placeholder="$t('choose.type')"
                                    :reduce="(type) => type.key"
                                    :searchable="false"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    AMOUNT    -->
                    <b-col v-show="computedAmountInput" md="4">
                        <label> Amount </label>
                        <ValidationProvider v-slot="{ errors }" name="Amount" rules="numeric">
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.amount"
                                    placeholder="Amount"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    LEVELS    -->
                    <b-col md="4">
                        <label>
                            {{ $t('navigation.levels') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('navigation.levels')"
                            rules="required"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="dataForModal.level_id"
                                    :class="[
                                        'select-size-lg',
                                        {
                                            'v-select--is-invalid': errors.length > 0
                                        }
                                    ]"
                                    :options="levels"
                                    :placeholder="$t('choose.level')"
                                    :reduce="(level) => level.id"
                                    :searchable="false"
                                    label="name"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PACKAGE ID    -->
                    <b-col v-if="dataForModal.type !== 'prize'" md="4">
                        <label>{{ $t('titles.package') }} ID</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.package')} ID`"
                            rules="required|numeric"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.package_id"
                                    :placeholder="`${$t('enter.packageID')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    COUNT    -->
                    <b-col md="4">
                        <label>
                            {{ $t('titles.amount') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.amount')"
                            rules="required|numeric"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.count"
                                    :placeholder="$t('enter.amount')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    COUNT DRAFT    -->
                    <b-col md="4">
                        <label>
                            {{ $t('titles.countDraft') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.countDraft')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    type="number"
                                    v-model="dataForModal.count_draft"
                                    :placeholder="$t('enter.countDraft')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    POSITION    -->
                    <b-col md="4">
                        <label>
                            {{ $t('titles.position') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.position')"
                            rules="required|numeric"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.position"
                                    :placeholder="$t('enter.position')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PROBABILITY    -->
                    <b-col v-if="!updatingItem" md="4">
                        <label>
                            {{ $t('titles.probability') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.probability')"
                            rules="required|numeric"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.probability"
                                    :placeholder="$t('probability')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PUBLISHED    -->
                    <b-col md="4">
                        <label></label>
                        <b-form-checkbox
                            v-model="dataForModal.published"
                            unchecked-value="0"
                            value="1"
                        >
                            {{ $t('titles.published') }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                    {{ $t('titles.add_more') }}
                </b-form-checkbox>
                <b-button variant="success" @click="modalSubmit">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
