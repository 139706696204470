<script>
import { BButton, BFormInput } from 'bootstrap-vue';

export default {
    name: 'CustomInput',
    components: {
        BFormInput,
        BButton
    },
    props: {
        value: [Number, String],
        type: {
            type: String,
            default: 'text',
            validator: (value) => {
                return ['text', 'number'].includes(value);
            }
        }
    },
    data: () => ({
        isSubmitBtnAvailable: false,
        isSubmitBtnDisabled: true
    }),
    computed: {
        computedModelValue: {
            get() {
                return this.value;
            },
            set(newVal) {
                this.$emit('input', newVal);
            }
        }
    },
    methods: {
        focus() {
            this.isSubmitBtnAvailable = true;
        },

        blur() {
            this.isSubmitBtnAvailable = false;
        },

        change() {
            this.isSubmitBtnDisabled = false;
        },

        onClickOutside({ target }) {
            if (!this.$el.contains(target)) {
                this.isSubmitBtnAvailable = false;
            }
        },

        submit() {
            this.$emit('submit');
        }
    },
    mounted() {
        window.addEventListener('click', this.onClickOutside);
    },
    beforeUnmount() {
        window.removeEventListener('click', this.onClickOutside);
    }
};
</script>

<template>
    <div
        :class="[
            'custom-input',
            {
                'custom-input--active': isSubmitBtnAvailable
            }
        ]"
    >
        <b-form-input
            :type="type"
            v-model="computedModelValue"
            class="custom-input__input"
            @focus="focus"
            @input="change"
        />
        <div class="custom-input__append">
            <slot name="append" />
        </div>
        <b-button
            size="sm"
            class="custom-input__btn"
            variant="success"
            :disabled="isSubmitBtnDisabled"
            @click="submit"
        >
            <feather-icon icon="CheckIcon" />
        </b-button>
    </div>
</template>

<style lang="scss" src="./CustomInput.scss" />
